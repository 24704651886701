function setBodyOverflow(isMobileNavOpen) {
    let overflow = "auto";

    if (isMobileNavOpen) {
        overflow = "hidden";
    }

    const documentBody = document.getElementsByTagName("body")[0];

    let style = `overflow: ${  overflow  };`;

    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);

    if (isMobileNavOpen && iOS) {
        style += "height: 100%; position: fixed;";
    } else {
        style += "height: auto; position: relative";
    }

    documentBody.style = style;
}

function isBrowser() {
    return typeof window !== "undefined";
}

export {
    isBrowser,
    setBodyOverflow
};
