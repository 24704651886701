import React, {useEffect, useState} from "react";
import { GridThemeProvider } from "styled-bootstrap-grid";
import GlobalStyles from "../../styles/GlobalStyles.js";
import "../../../node_modules/normalize.css/normalize.css";
import Icons from "../Core/Icons.js";
import SEO from "../SEO.js";
import Bar from "./Bar";
import Nav from "./Nav.js";
import Footer from "./Footer.js";
import SignUp from "../SignUp.js";

const gridTheme = {
    row: {
        padding: 0,
    },
    col: {
        padding: 0
    },
};

export default function Layout({ children, data, url }) {
    const [isSearchForm, setSearchForm] = useState(false);
    const [isProperty, setProperty] = useState(false);
    const [isActive, setActive] = useState(true);
    const {settings} = data;
    const regions = data.regions.nodes;
    const prop = data.property;
    let searchForm = null;
    let pb;

    useEffect(() => {
        searchForm = document.getElementById("search-form");

        if (searchForm !== null && isSearchForm === false) {
            setSearchForm(true);
        } else if (searchForm === null && isSearchForm === true) {
            setSearchForm(false);
        }

        if (prop !== undefined && isProperty === false) {
            setProperty(true);
        } else if (prop === undefined && isProperty === true) {
            setProperty(false);
        }

        // get / set initial entered page in session storage for use in utm params
        if (typeof window !== "undefined") {
          !window.sessionStorage.getItem("landingUrl") ? window.sessionStorage.setItem("landingUrl", url) : null; 
        }

        // check if user has seen the email signup modal before
        if (typeof window !== "undefined"){
            if (localStorage.getItem("popUpSeen") === "true") {
                setActive(false);
            }
        }
    });

    const hidePopup = () => {
        setActive(false);
        localStorage.setItem("popUpSeen", "true");
    };

    const SEOOverrides = {};
    SEOOverrides.robots = "all";
    
    if (typeof window !== "undefined") {
      if (isProperty) {
        window.pageType =  "property";
      } else {
        window.pageType =  "";
      }
    }

    // SEO overrides 
    switch(true) {
        case data.region !== undefined:
            data.region.titleSEO ? SEOOverrides.title = data.region.titleSEO : null;
            data.region.descriptionSEO ? SEOOverrides.description = data.region.descriptionSEO : null;
            data.region.keywordsSEO ? data.region.keywordsSEO.length > 0 ? SEOOverrides.keywords = data.region.keywordsSEO : null : null;
            data.region.noindex ? SEOOverrides.robots = "noindex" : SEOOverrides.robots = "all";
            pb = data.region.pageBuilder || [];
            break;
        case data.ppc !== undefined:
            data.ppc.titleSEO ? SEOOverrides.title = data.ppc.titleSEO : null;
            data.ppc.descriptionSEO ? SEOOverrides.description = data.ppc.descriptionSEO : null;
            data.ppc.keywordsSEO ? data.ppc.keywordsSEO.length > 0 ? SEOOverrides.keywords = data.ppc.keywordsSEO : null : null;
            SEOOverrides.robots = "noindex";
            pb = data.ppc.pageBuilder || [];
            break;
        case data.property !== undefined:
            data.property.titleSEO ? SEOOverrides.title = data.property.titleSEO : null;
            data.property.descriptionSEO ? SEOOverrides.description = data.property.descriptionSEO : null;
            data.property.keywordsSEO ? data.property.keywordsSEO.length > 0 ? SEOOverrides.keywords = data.property.keywordsSEO : null : null;
            data.property.noindex ? SEOOverrides.robots = "noindex" : SEOOverrides.robots = "all";
            pb = data.property.pageBuilder || [];
            break;
        case data.blog !== undefined:
            data.blog.titleSEO ? SEOOverrides.title = data.blog.titleSEO : null;
            data.blog.descriptionSEO ? SEOOverrides.description = data.blog.descriptionSEO : null;
            data.blog.keywordsSEO ? data.blog.keywordsSEO.length > 0 ? SEOOverrides.keywords = data.blog.keywordsSEO : null : null;
            data.blog.noindex ? SEOOverrides.robots = "noindex" : SEOOverrides.robots = "all";
            pb = data.blog.pageBuilder || [];
            break;
        case data.home !== undefined:
            pb = data.home.pageBuilder || [];
            break;
        case url === "/contact/":
            SEOOverrides.title = "Contact us - Hot Tub Hideaways - Find Your Hot Tub Escape";
            break;
    }

    (pb || []).map((c, i) => {
        switch (c._type) {
            case "trustpilot":
                SEOOverrides.trustPilot = true;
                break;
        }
    });

    return (
      <>
        <Icons />
        <SEO {...settings} {...SEOOverrides} path={url} />
        <GridThemeProvider gridTheme={gridTheme}>
          <GlobalStyles {...settings} />
          <Bar />
          <Nav regions={regions} settings={settings} isSearchForm={isSearchForm} isProperty={isProperty} />
          {isActive && (
                <SignUp hidePopup={hidePopup} settings={settings}/>
            )}
          { children }
          <Footer {...settings} property={!!data.property} />
        </GridThemeProvider>
      </>
    );
}