import React, {useCallback, useEffect, useState} from "react";
import {Link} from "gatsby";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import {isBrowser, setBodyOverflow} from "../../services/browser";
import NavLinks from "./Nav/NavLinks";
import Container from "../Core/Container";
import Icon from "../Core/Icon";

const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 4rem;
  width: 100%;
  background: transparent;
  position: ${props => props.isNavFixed ? "fixed" : "relative"};
  z-index: 9999;
  top: ${props => (((props.isNavFixed && props.visible) || props.isMobileNavOpen) ? "0" : (props.isNavFixed && !props.visible && "-4rem"))};
  transition: 0.3s;
  background: var(--primary);
  box-shadow: rgb(0 0 0 / 30%) -1px 6px 1rem -4px;
  
  &[data-show="true"] {
    position: fixed;
    top: 0;
  }

  @media only screen and (min-width: ${breakpoints.lg}){
    top: ${props => (props.isNavFixed && props.visible) ? "0" : (props.isNavFixed && !props.visible) && "-5.5rem"};
    height: 5.5rem;

    &[data-show="true"] {
      position: ${props => props.isNavFixed ? "fixed" : "relative"};
    }

    & > div {
      position: unset;
    }
  }
`;

const List = styled.div`
  display: inline-flex;
  width: 100%;
`;

const Button = styled.button`
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0 3px;
  
  svg {
    fill: var(--white);
    height: 18px;
    width: 18px;
  }
`;

const MobileNav = styled.div`
  height: ${props => props.isNavFixed ? "calc(100vh - 4rem)" : "calc(100vh - 6.75rem)"};
  position: fixed;
  transition: 0.3s;
  top: ${props => props.isNavFixed ? "4rem" : "6.75rem"};
  right: ${props => props.isMobileNavOpen ? "0" : "-100vw"};
  z-index: 999;
  background: rgba(0,0,0,0.9);
  width: 100%;
  overflow: scroll;
  
  @media only screen and (min-width: ${breakpoints.lg}){
    display: none;
  }
`;

const ListContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 4rem;
  width: 100%;

  @media only screen and (min-width: ${breakpoints.lg}) {
    height: 5.5rem;
  }
`;

const LogoLink = styled(Link)`
  height: 4rem;
  display: flex;
  align-items: center;

  @media only screen and (min-width: ${breakpoints.lg}){
    height: 5.5rem;
    min-width: 10rem;
    box-sizing: border-box;
  }
`;

const Img = styled.img`
  height: 2.75rem;
  
  @media only screen and (min-width: ${breakpoints.lg}){
    height: 3.75rem;
    width: 9.8rem;
  }
`;

const Background = styled.div`
  background: var(--primary);
  height: 4rem;
  transition: .3s;

  @media only screen and (min-width: ${breakpoints.lg}){
    height: 5.5rem;
  }
`;

const SearchBox = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0.5rem 0.75rem 1rem;
  box-sizing: border-box;
  background: var(--white);
  border: 1px solid var(--white);
  opacity: 0;
  margin: auto 1.25rem auto 2.5rem;
  border-radius: 0.625rem;
  width: 25rem;
  height: 3.125rem;
  cursor: ${props => props.visible ? "text" : "default"};
  
  p {
    margin-bottom: 0;
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    display: flex;
    opacity: ${props => props.visible && "1"};
    transition: .3s;
  }

  @media only screen and (min-width: 1180px) {
    width: 30rem;
  }

  @media only screen and (min-width: 1280px) {
    width: 35rem;
  }



`;

const IconBox = styled.div`
  height: 2.375rem;
  width: 2.375rem;
  cursor: ${props => props.visible ? "pointer" : "default"};
    
  svg {
    height: 100%;
    width: 100%;
    fill: var(--primary);
  }
`;

const MobileIconBox = styled.div`
  display: block;
  position: fixed;
  bottom: ${props => props.isProperty ? "4.25rem" : "1.25rem"};
  right: 1.25rem;
  height: 3.125rem;
  width: 3.125rem;
  cursor: pointer;
  
  svg {
    height: 100%;
    width: 100%;
    fill: var(--secondary);
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const MOBILE_NAV_HEIGHT = 64;
const DESKTOP_NAV_HEIGHT = 88;

const Nav = (props) => {
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const [navHeight, setNavHeight] = useState(MOBILE_NAV_HEIGHT);
    const [isDesktop, setIsDesktop] = useState(false);
    const [isNavFixed, setNavFixed] = useState(false);
    const [linksOpen ,setLinksOpen] = useState(null);
    const [searchBoxVisible, setSearchBoxVisible] = useState(false);
    const [previousUrl, setPreviousUrl] = useState("");
    const [currentUrl, setCurrentUrl] = useState("");

    const linksOpened = linksOpen !== null;
    const {isSearchForm, isProperty} = props;
    let pageHeight;
    let searchForm;

    const toggleLink = (i, executeOnDesktop) => {
        if (isDesktop && executeOnDesktop) {
            setLinksOpen((linksOpen !== i) ? i : null);
        }

        if (!isDesktop && !executeOnDesktop){
            setLinksOpen((linksOpen !== i) ? i : null);
        }
    };

    if (typeof window !== "undefined") {
        searchForm = document.getElementById("search-form");
    }

    const handleScroll = useCallback(() => {
        if (isBrowser()) {
            const { scrollY } = window;
            const scrollSensitivity = 7;

            if (typeof window !== "undefined") {
                pageHeight = window.innerHeight - 200;
            }

            if (prevScrollPos > (scrollY + scrollSensitivity) || (scrollY < navHeight)){
                setVisible(true);

                if ((prevScrollPos > pageHeight) && !searchBoxVisible){
                    setSearchBoxVisible(true);
                } else if ((prevScrollPos < pageHeight) && searchBoxVisible){
                    setSearchBoxVisible(false);
                }
            } else if (prevScrollPos < (scrollY - scrollSensitivity) && scrollY > navHeight){
                setVisible(false);
            }

            if (scrollY > 44 && isNavFixed === false){
                setNavFixed(true);
            } else if (scrollY <= 44 && isNavFixed === true) {
                setNavFixed(false);
            } else if (isNavFixed === false && linksOpened === true){
                setNavFixed(false);
            }

            setPrevScrollPos(scrollY);
        }
    }, [prevScrollPos, navHeight]);

    useEffect(() => {
        setBodyOverflow(isMobileNavOpen);
        const onResize = () => {
            setIsDesktop(window.innerWidth >= 1024);
            const newNavHeight = isDesktop ? DESKTOP_NAV_HEIGHT : MOBILE_NAV_HEIGHT;

            if (!navHeight || (navHeight !== newNavHeight)) {
                setNavHeight(newNavHeight);
            }
            if (linksOpen !== null){
                setVisible(true);
            }
        };

        if (isBrowser()) {
            onResize();
            window.addEventListener("scroll", handleScroll);
            window.addEventListener("resize", onResize);
            return () => window.removeEventListener("scroll", handleScroll);
        }
    }, [prevScrollPos, visible, handleScroll, navHeight, isMobileNavOpen]);

    const closeMenu = () => {
        setTimeout(() => {
            setIsMobileNavOpen(false);
        }, 100);
    };

    useEffect(() => {
        if (typeof window !== "undefined") {
            setCurrentUrl(window.location.pathname);

            if (previousUrl && previousUrl !== window.location.pathname) {
                setIsMobileNavOpen(false);
            }

            setPreviousUrl(window.location.pathname);
        }
    }, [typeof window !== "undefined" ? window.location.pathname : null]);

    const scrollToForm = () => {
        if (searchForm !== null){
            const regionInput = document.getElementById("region");

            setTimeout(() => {
                if (typeof window !== "undefined") {
                    if (isDesktop && (searchBoxVisible === true)){
                        window.scrollTo({
                            top: searchForm.getBoundingClientRect().top + window.pageYOffset - 100,
                            behavior: "smooth",
                        });
                    }
                }
                regionInput.focus();
            }, 50);
        }

    };

    function setFocus(idInput){
        document.getElementById(idInput).focus();
    }

    const links = [
        {
            name: "Hot tub lodges",
            url: "/lodges/",
            subLinks: [
                {
                    name: "Heart of England",
                    links: [
                        {
                            name: "Derbyshire",
                            url: "/lodges/heart-england/derbyshire-hideaways/",
                        },
                        {
                            name: "Peak District",
                            url: "/lodges/peak-district/",
                        },
                        {
                            name: "Near Alton Towers",
                            url: "/lodges/heart-england/staffordshire-hideaways/alton-towers/",
                        },
                        {
                            name: "Gloucestershire",
                            url: "/lodges/heart-england/gloucestershire-hideaways/",
                        },
                        {
                            name: "Leicestershire",
                            url: "/lodges/heart-england/leicestershire-hideaways/",
                        },
                        {
                            name: "Midlands",
                            url: "/lodges/heart-england/midlands/",
                            links: [
                                {
                                    name: "Warwickshire",
                                    url: "/lodges/heart-england/midlands/warwickshire/",
                                },
                                {
                                    name: "Worcestershire",
                                    url: "/lodges/heart-england/midlands/worcestershire/",
                                }
                            ]
                        },
                        {
                            name: "Nottinghamshire",
                            url: "/lodges/heart-england/nottinghamshire/",
                            links: [
                                {
                                    name: "Sherwood Forest",
                                    url: "/lodges/sherwood-forest/",
                                },
                            ]
                        },
                        {
                            name: "Shropshire",
                            url: "/lodges/heart-england/shropshire-hideaways/",
                        },
                        {
                            name: "Staffordshire",
                            url: "/lodges/heart-england/staffordshire-hideaways/",
                        },
                    ]
                },
                {
                    name: "East of England",
                    links: [
                        {
                            name: "Cambridgeshire",
                            url: "/lodges/east-of-england/cambridgeshire-hideaways/",
                        },
                        {
                            name: "Essex",
                            url: "/lodges/east-of-england/essex-hideaways/",
                        },
                        {
                            name: "Lincolnshire",
                            url: "/lodges/east-of-england/lincolnshire-hideaways/",
                        },
                        {
                            name: "Norfolk",
                            url: "/lodges/east-of-england/norfolk-hideaways/",
                        },
                        {
                            name: "Suffolk",
                            url: "/lodges/east-of-england/suffolk-hideaways/",
                        }
                    ]
                },
                {
                    name: "South West",
                    links: [
                        {
                            name: "Bournemouth",
                            url: "/lodges/south-west-of-england/dorset/bournemouth/",
                        },
                        {
                            name: "Cornwall",
                            url: "/lodges/south-west-of-england/cornwall/",
                        },
                        {
                            name: "Devon",
                            url: "/lodges/south-west-of-england/devon/",
                        },
                        {
                            name: "Dorset",
                            url: "/lodges/south-west-of-england/dorset/",
                        },
                        {
                            name: "Somerset",
                            url: "/lodges/south-west-of-england/somerset/",
                        },
                    ]
                },
                {
                    name: "The South",
                    links: [
                        {
                            name: "Cotswolds",
                            url: "/lodges/south-england/cotswolds/",
                        },
                        {
                            name: "Hampshire",
                            url: "/lodges/south-england/hampshire-hideaways/",
                        },
                        {
                            name: "Isle of Wight",
                            url: "/lodges/south-england/isle-wight-hideaways/",
                        },
                        {
                            name: "Kent",
                            url: "/lodges/south-england/kent/",
                        },
                        {
                            name: "Oxfordshire",
                            url: "/lodges/south-england/oxfordshire/",
                        },
                        {
                            name: "Sussex",
                            url: "/lodges/south-england/sussex-hideaways/",
                        },
                    ]
                },
                {
                    name: "Scotland",
                    url:"/lodges/scotland/",
                    links: [
                        {
                            name: "Aberdeenshire",
                            url: "/lodges/scotland/aberdeenshire/",
                        },
                        {
                            name: "Aviemore",
                            url: "/lodges/scotland/aviemore/",
                        },
                        {
                            name: "Ayrshire",
                            url: "/lodges/scotland/ayrshire/"
                        },
                        {
                            name: "Dundee",
                            url: "/lodges/scotland/dundee/",
                        },
                        {
                            name: "Glasgow",
                            url: "/lodges/scotland/glasgow/",
                        },
                        {
                            name: "Inverness",
                            url: "/lodges/scotland/southern-highlands-hideaways/inverness/",
                        },
                        {
                            name: "Loch Lomond",
                            url: "/lodges/scotland/loch-lomond/",
                        },
                        {
                            name: "Northern Highlands",
                            url: "/lodges/scotland/northern-highlands-hideaways/",
                        },
                        {
                            name: "Perthshire",
                            url: "/lodges/scotland/perthshire/"
                        },
                        {
                            name: "Southern Highlands",
                            url: "/lodges/scotland/southern-highlands-hideaways/"
                        },
                        {
                            name: "South West Scotland",
                            url: "/lodges/scotland/south-west-scotland-hideaways/"
                        },
                    ]
                },
                {
                    name: "North Of England",
                    links: [
                        {
                            name: "Yorkshire",
                            url: "/lodges/north-england/yorkshire/",
                            links: [
                                {
                                    name: "Scarborough Lodges",
                                    url: "/lodges/north-england/yorkshire/scarborough/",
                                },
                                {
                                    name: "Whitby Lodges",
                                    url: "/lodges/north-england/yorkshire/whitby/",
                                },
                            ]
                        },
                    ]
                },
                {
                    name: "North East",
                    url:"/lodges/north-east/",
                    links: [
                        {
                            name: "Durham",
                            url: "/lodges/north-east/county-durham/",
                        },
                        {
                            name: "Newcastle",
                            url: "/lodges/north-england/newcastle/",
                        },
                        {
                            name: "Northumberland",
                            url: "/lodges/north-england/northumberland/",
                        },
                    ]
                },
                {
                    name: "North West",
                    url: "/lodges/north-west-england/",
                    links: [
                        {
                            name: "Cheshire",
                            url: "/lodges/north-west-england/cheshire/",
                            links: [
                                {
                                    name: "Chester",
                                    url: "/lodges/north-west-england/chester/",
                                },
                            ]
                        },
                        {
                            name: "Cumbria",
                            url: "/lodges/north-england/cumbria/",
                            links: [
                                {
                                    name: "Lake District",
                                    url: "/lodges/north-west-england/lake-district/",
                                },
                                {
                                    name: "Windermere",
                                    url: "/lodges/north-west-england/lake-district/windermere/",
                                },
                            ]
                        },
                        {
                            name: "Lancashire",
                            url: "/lodges/north-england/lancashire-hideaways/",
                            links: [
                                {
                                    name: "Blackpool",
                                    url: "/lodges/north-england/lancashire-hideaways/blackpool/",
                                },
                            ]
                        }
                    ]
                },
                {
                    name: "Wales",
                    url:"/lodges/wales/",
                },
                {
                    name: "Northern Ireland",
                    url:"/lodges/northern-ireland/",
                },
            ],
        },
        {
            name: "Other properties",
            url: "",
            subLinks: [
                {
                    name: "Hotels",
                    url: "/hotels/",
                    links: [
                        {
                            name: "Lake District",
                            url: "/hotels/lake-district/",
                        },
                        {
                            name: "Liverpool",
                            url: "/hotels/liverpool/",
                        },
                        {
                            name: "London",
                            url: "/hotels/london/",
                        },
                        {
                            name: "Manchester",
                            url: "/hotels/manchester/",
                        },
                    ]
                },
                {
                    name: "Cottages",
                    url: "/cottages/",
                    links: [
                        {
                            name: "By the Coast",
                            url: "/cottages/coastal/",
                        },
                        {
                            name: "Large Cottages",
                            url: "/cottages-that-sleep-12/",
                        },
                    ]
                },
                {
                    name: "Glamping",
                    url: "/glamping/",
                    links: [
                        {
                            name: "Cornwall",
                            url: "/glamping/cornwall/",
                        },
                        {
                            name: "Derbyshire",
                            url: "/glamping/derbyshire/"
                        },
                        {
                            name: "Scotland",
                            url: "/glamping/scotland/",
                        },
                        {
                            name: "Devon",
                            url: "/glamping/devon/",
                        },
                        {
                            name: "Dorset",
                            url: "/glamping/dorset/"
                        },
                        {
                            name: "Essex",
                            url: "/glamping/essex/"
                        },
                        {
                            name: "Yorkshire",
                            url: "/glamping/yorkshire/",
                        },
                        {
                            name: "Northumberland",
                            url: "/glamping/northumberland/",
                        },
                        {
                            name: "Peak District",
                            url: "/glamping/peak-district/",
                        },
                        {
                            name: "Kent",
                            url: "/glamping/kent/",
                        },
                        {
                            name: "Wales",
                            url: "/glamping/wales/",
                        },
                        {
                            name: "Northern Ireland",
                        },
                        {
                            name: "Lake District",
                            url: "/glamping/lake-district/",
                        },
                        {
                            name: "New Forest",
                            url: "/glamping/new-forest/",
                        },
                    ]
                },
                {
                    name: "Safari Tents",
                    url: "/glamping/safari-tents/"
                },
                {
                    name: "Wigwams",
                    url: "/glamping/wigwams/"
                },
                {
                    name: "Treehouses",
                    url: "/glamping/treehouses/",
                },
                {
                    name: "Caravan Sites",
                    url: "/caravans/",
                },
                {
                    name: "Apartments",
                    url: "/apartments/"
                },
            ]
        },
        {
            name: "Perfect for",
            url: "",
            subLinks: [
                {
                    name: "Romance / Couples",
                    url: "/romantic-getaways-uk/",
                },
                {
                    name: "Pets / Dogs",
                    url: "/dog-friendly-holidays/",
                },
                {
                    name: "Fishing",
                    url: "/lodges/fishing/",
                },
                {
                    name: "Budget Breaks",
                    url: "/cheap-weekend-breaks-uk/",
                },
                {
                    name: "Hen Parties",
                    url: "/lodges/hen-party/",
                },
                {
                    name: "Luxury",
                    url: "/lodges/luxury/"
                }
            ]
        },
        {
            name: "List your property",
            url: "https://www.snaptripgroup.com/list-your-property",
            target: "_blank"
        },
    ];
    
    return (
      <>
        {isNavFixed && <Background />}
        <NavContainer visible={visible} id="nav" isNavFixed={isNavFixed} isMobileNavOpen={isMobileNavOpen}>
          <Container>
            <List>
              <LogoLink to="/" onClick={() => setIsMobileNavOpen(false)}>
                <Img alt="Hottub Hideaways Logo" src={props.settings.logo.asset.fixed.src} />
              </LogoLink>
              {isSearchForm && (
              <SearchBox visible={searchBoxVisible} onClick={() => scrollToForm()}>
                <p>Start your search</p>
                <IconBox visible={searchBoxVisible}>
                  <Icon name="icon-loupe" />
                </IconBox>
              </SearchBox>
              )}

              <ListContent>
                {!isDesktop &&(
                <Button aria-label="Open mobile menu" onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}>
                    {isMobileNavOpen ? <Icon name="icon-cross" /> : <Icon name="icon-burger" />}
                </Button>
                )}
                {isDesktop && (
                <NavLinks linksOpen={linksOpen} toggleLink={toggleLink} links={links} isDesktop={isDesktop} headerVisible={visible} />
                )}
              </ListContent>
            </List>
          </Container>
          {!isDesktop && (
          <MobileNav isMobileNavOpen={isMobileNavOpen} isNavFixed={isNavFixed}>
            <NavLinks closeMenu={closeMenu} isMobileNavOpen={isMobileNavOpen} linksOpen={linksOpen} toggleLink={toggleLink} links={links} isDesktop={isDesktop} headerVisible={visible} />
          </MobileNav>
              )}
          {isSearchForm && (
          <MobileIconBox onClick={() => setFocus("region")} isProperty={isProperty}>
            <Icon name="icon-loupe" />
          </MobileIconBox>
           )}
        </NavContainer>
      </>
    );
};

export default Nav;