import React, {useState} from "react";
import styled from "styled-components";
import {Link} from "gatsby";
import breakpoints from "../../../styles/breakpoints";
import Icon from "../../Core/Icon";

const Ul = styled.ul`
  display: ${props => props.opened ? "flex" : "none"};
  grid-template-columns: ${props => props.otherProps && "1fr 1fr 1fr"};
  flex-direction: column;
  list-style: none;
  padding-inline-start: 1rem;
  margin-top: 0.5rem;
  
  @media only screen and (min-width: ${breakpoints.sm}) {
    padding-inline-start: 1.5rem;
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    display: ${props => (props.opened || props.fullWidth) && !props.otherProps ? "flex" : (props.opened || props.fullWidth) && props.otherProps ? "grid" : "none"};
    padding-inline-start: 0;
  }
`;

const Li = styled.li`
  padding: 0.5rem 0;
  margin: 0 1rem 0 0 !important;

  @media only screen and (min-width: ${breakpoints.sm}) {  
    margin: 0 1.5rem 0 0 !important;
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    margin: 0 !important;
  }
`;

const SubUl = styled(Ul)`
  padding-inline-start: 1rem;
  padding-top: 0.5rem;
  
  li {
    padding: 0.25rem 0;
  }

  @media only screen and (min-width: ${breakpoints.sm}) {
    padding-inline-start: .5rem;
  }
`;

const LinkBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LinkStyled = styled(Link)`
  color: var(--white);
  text-decoration: none;
  font-weight: ${props => props.selected ? "700" : "normal"};

  @media only screen and (min-width: ${breakpoints.lg}) {
    font-size: 0.875rem;
    
    &:hover {
      font-weight: 700;
    }
  }
`;

const IconBox = styled.div`
  width: 1rem;
  height: 1rem;
  transform: ${props => props.opened ? "rotate(180deg)" : "rotate(360deg)"};
  transition: .3s;
  cursor: pointer;
  display: block;
  margin: 0 2px 0 auto;
  
  svg {
    fill: ${props => props.opened ? "var(--secondary)" : "var(--white)"};
    width: 100%;
    height: 100%;
  }
  
  &:hover {
    transform: rotate(180deg);
    transition: .3s;
  }
`;

const LinkElement = styled.div`
    color: var(--white);
    text-decoration: none;
    font-weight: ${props => props.selected ? "700" : "normal"};

    @media only screen and (min-width: ${breakpoints.lg}) {
        font-size: 0.875rem;

        &:hover {
            font-weight: 700;
        }
    }
`;

const SubLinks = ({opened, subLinks, isDesktop, fullWidth, otherProps, closeMenu}) => {
    const [linksOpen ,setLinksOpen] = useState(null);

    const toggleLink = (i, executeOnDesktop) => {
        if ((isDesktop && executeOnDesktop) || (!isDesktop && !executeOnDesktop) || (!isDesktop && executeOnDesktop)) {
            setLinksOpen((linksOpen !== i) ? i : null);
        }
    };

    return (
      <Ul opened={opened} fullWidth={fullWidth} otherProps={otherProps}>
        {subLinks.map((subLink, i) => {
            const {links} = subLink;
            const linksOpened = linksOpen === i;

             return (
               <Li key={i}>
                 <LinkBlock opened={linksOpened}>
                   {subLink.url ? (
                     <LinkStyled
                       to={subLink.url}
                       key={i}
                       aria-label={subLink.name}
                       selected={links && linksOpened}
                       onClick={() => closeMenu()}
                     >
                       {subLink.name}
                     </LinkStyled>
                       )
                         : (
                           <LinkElement
                             key={i}
                             aria-label={subLink.name}
                             selected={links && linksOpened}
                             onClick={() => closeMenu()}
                           >
                             {subLink.name}
                           </LinkElement>
                       )}
                   {links && (
                     <IconBox onClick={() => toggleLink(i, true)} opened={linksOpened}>
                       {linksOpened ? <Icon name="icon-minus" /> : <Icon name="icon-plus" />}
                     </IconBox>
                       )}
                 </LinkBlock>
                 {links && (
                   <SubUl opened={linksOpened}>
                     {links.map((link, j) => {
                       return (
                         <li key={j}>
                           {link.url ? (
                             <LinkStyled
                               to={link.url}
                               key={j}
                               aria-label={link.name}
                               onClick={() => closeMenu()}
                             >
                               {link.name}
                             </LinkStyled>
                             )
                               : (
                                 <LinkElement
                                   key={j}
                                   aria-label={link.name}
                                   onClick={() => closeMenu()}
                                 >
                                   {link.name}
                                 </LinkElement>
                             )}
                         </li>
                          );
                      })}
                   </SubUl>
                  )}
               </Li>
              );
          })}
      </Ul>
    );
};

export default SubLinks;