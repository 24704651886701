import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import {Row, Col} from "styled-bootstrap-grid";
import Container from "../Core/Container";
import breakpoints from "../../styles/breakpoints";
import Icon from "../Core/Icon";

const FooterSection = styled.section`
  background: var(--darkGrey);
`;

const Ul = styled.ul`
  list-style: none;
  padding-inline: 0;
  margin-block: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  
  li {
    margin: 0;
  }
  
  p {
    color: var(--white);
  }
  
  p, a {
    margin: 0;
    padding: 0.5rem 0;
    font-size: 1rem;
    line-height: 1rem;
    margin-block: 0;
  }

  @media only screen and (min-width: ${breakpoints.md}) {
    
  }
`;

const LinkStyled = styled(Link)`
  text-decoration: none;
  display: block;
  width: max-content;
  color: ${props => props.active ? "var(--secondary)" : "white"};

  @media only screen and (min-width: ${breakpoints.md}) {
    transition: .2s;

    &:hover, &:focus {
      color: var(--secondary);
      transition: .2s;
    }
  }
`;

const LogoLink = styled(Link)`
  width: 7.375rem;
  margin-bottom: 1rem;
  display: block;
  
  img {
    width: 100%;
  }

  @media only screen and (min-width: ${breakpoints.md}) {
    width: 9.5rem;
    margin-top: .25rem;
    margin-bottom: 0;
  }
`;

const Content = styled.div`
  padding: 1.5rem 0;
  
  @media only screen and (min-width: ${breakpoints.md}) {
    padding: 2.125rem 0;
  }
`;

const Bottom = styled.div`
  padding: 1.625rem 0;
  background: var(--grey);
`;

const BottomContent = styled.div`
  p, a {
    color: var(--black);
    font-size: 0.625rem;
    margin-bottom: 0;
  }

  p:last-of-type {
    margin-top: 1rem;
  }

  @media only screen and (min-width: ${breakpoints.md}) {
    text-align: right;
  }
`;

const Logos = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem;
  
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
  }
  
  svg {
    fill: var(--white);
    margin: 0 1.25rem 0 0;
    float: right;
    transition: .2s;

    &:hover, &:focus {
      fill: var(--secondary);
      transition: .2s;
    }
  }
  
  .icon {
    &-snaptrip {
      height: 1.563rem;
      width: 4rem;
    }
    &-lmc {
      height: 1.863rem;
      width: 6rem;
    }
    &-lr {
      width: 8rem;
      height: 1.563rem;
    }
    &-bc {
      height: 2.2rem;
      width: 6.6rem;
    }
    &-dfc {
      width: 5.4rem;
      height: 1.563rem;
      stroke: var(--white);
      
      &:hover, &:focus {
        stroke: var(--secondary);
      }
    }
    &-vh {
      width: 3rem;
      height: 2.7rem;
    }
    &-ik {
      width: 4rem;
      height: 3.7rem;
    }
  }

  @media only screen and (min-width: ${breakpoints.md}) {
    margin-top: 0;
    justify-content: flex-end;
  }
`;

const Break = styled.div`
  @media only screen and (min-width: ${breakpoints.lg}) {
    flex-basis: 100%;
    height: 0;
  }
`;

export default function Footer(settings) {
  const year = new Date().getFullYear();
  let pathname;
  
  if (typeof window !== "undefined") {
    pathname = window.location.pathname;
  }

  const footerLinks = [
    {
      label: "About us",
      url: "/about-us/"
    },
    {
      label: "Blog",
      url: "/blog",
    },
    {
      label: "Terms of service",
      url: "/terms-of-service/"
    },
    {
      label: "Privacy policy",
      url: "/privacy-policy/"
    },
    {
      label: "Contact",
      url: "/contact/"
    },
    {
      label: "List your property",
      url: "https://www.snaptripgroup.com/list-your-property",
      target: "_blank"
    },
    {
      label: "Sitemap",
      url: "/sitemap/",
      target: "_blank"
    }
  ];

    return (
      <FooterSection>
        <Container>
          <Content>
            <Row>
              <Col md={3}>
                <LogoLink to="/">
                  <img alt="Hottub Hideaways Logo" src={settings.logo.asset.fixed.src} />
                </LogoLink>
              </Col>
              <Col md={4}>
                <Ul>
                  {footerLinks.map((link, i) => {
                    const {url} = link;

                    return (
                      <li key={i}>
                        <LinkStyled to={link.url} active={pathname === url ? 1 : 0} target={link.target ? link.target : "_self"}>
                          {link.label}
                        </LinkStyled>
                      </li>
                    );
                  })}
                </Ul>
              </Col>
              <Col md={5}>
                <Logos>
                  <a href="https://www.snaptrip.com/" target="_blank" rel="noreferrer">
                    <Icon name="icon-snaptrip" />
                  </a>
                  <a href="https://www.laterooms.com/" target="_blank" rel="noreferrer">
                    <Icon name="icon-lr" />
                  </a>
                  <a href="https://www.dogfriendlycottages.co.uk/" target="_blank" rel="noreferrer">
                    <Icon name="icon-dfc" />
                  </a>
                  <Break />
                  <a href="https://big-cottages.com/" target="_blank" rel="noreferrer">
                    <Icon name="icon-bc" />
                  </a>
                  <a href="https://www.lastminute-cottages.co.uk/" target="_blank" rel="noreferrer">
                    <Icon name="icon-lmc" />
                  </a>
                  <a href="https://www.villaholidays.com/" target="_blank" rel="noreferrer">
                    <Icon name="icon-vh" />
                  </a>
                </Logos>
              </Col>
            </Row>
          </Content>
        </Container>
        <Bottom>
          <Container>
            <Row>
              <Col md={4} mdOffset={8}>
                <BottomContent>
                  <p>
                    &copy;
                    {" "}
                    Hot Tub Hideaways
                    {" "}
                    {year}
                  </p>
                  <p>"Hot Tub Hideaways" is a trading name of Snaptrip Group Limited</p>
                  <p>Registered in England and Wales.</p>
                  <p>Company Registration Number 08774859.</p>
                  <p>VAT Registration Number 201864825.</p>
                  <p>
                    <b>
                      Part of the
                      {" "}
                      <a href="https://www.snaptripgroup.com/" target="_blank" rel="noreferrer">Snaptrip Group</a>
                    </b>
                  </p>
                </BottomContent>
              </Col>
            </Row>
          </Container>
        </Bottom>
      </FooterSection>
    );
}