import React, {useState} from "react";
import styled from "styled-components";
import CrossIcon from "../assets/icons/cross2.svg";
import CrossIconWhite from "../assets/icons/cross.svg";

const SignContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 101;
`;

const Overlay = styled.div`
  background: var(--secondary);
  opacity: 0.85;
  height: 100vh;
  width: 100vw;
  z-index: 101;
  position: absolute;
  top: 0;
  left: 0;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0 1.25rem;
`;

const Block = styled.div`
  position: relative;
  background: ${props => props.primaryColor ? "var(--primary)" : "#FFF"};
  z-index: 102;
  max-width: 500px;
  padding: 3rem 1.25rem 2.25rem;
  border-radius: 0.313rem;
  h2 {
    margin-bottom: 1.25rem;
    text-align: center;
    color: ${props => props.primaryColor ? "#FFF" : "#000"};;
  }
  @media only screen and (min-width: 1024px) {
    padding: 3.75rem 2.25rem;
    p {
      margin-bottom: 1.5rem;
    }
  }
`;

const CloseButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0;
  img {
    cursor: pointer;
    height: .875rem;
  }
  @media only screen and (min-width: 1024px) {
    top: 1.25rem;
    right: 1.25rem;
  }
`;

const Form = styled.form`
  input {
    border: 1px solid #cacaca;
    width: 100%;
    outline: none;
    padding: 1rem 1.25rem;
    box-sizing: border-box;
    border-radius: 0.313rem;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  p {
    font-size: .65rem;
    margin-top: .625rem;
    margin-bottom: 1.25rem;
    color: ${props => props.primaryColor ? "#FFF" : "#000"};
    a {
      color: ${props => props.primaryColor ? "#FFF" : "#000"};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  button {
    display: block;
    height: 3.5rem;
    border: none;
    padding: 0;
    background: ${props => props.primaryColor ? "#FFF" : "var(--secondary)"};
    color: ${props => props.primaryColor ? "var(--secondary)" : "#FFF"};
    font-weight: 700;
    cursor: pointer;
    border-radius: 0.313rem;
    width: 11.25rem;
    margin: 0 auto;
    &:hover,
    &:focus {
      background: ${props => props.primaryColor ? "#EEEEEE" : "var(--lightenButton)"};
      transition: .3s;
    }
  }
`;

const SignUp = ({hidePopup, settings}) => {
    const [email, setEmail] = useState("");
    const [isLoading , seIsLoading] = React.useState(true);

    const displayPopup = settings.signUpDisplay && !!settings.zapierEndpoint;
    console.log('in signup', displayPopup)

    const submit = (e) => {
        e.preventDefault();
        const url = `${settings.zapierEndpoint}/?email=${email}`;

        const xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
        xhr.send();

        setTimeout(() => {
            hidePopup();
            setEmail("");
        }, 1000);
    };

    React.useEffect(()=>{
        if (displayPopup) {
            let timer1 = setTimeout(() => seIsLoading(false), 5000);

            return () => {
              clearTimeout(timer1);
            };
        }
    },[]);

    return (
        <>
            {(displayPopup && !isLoading) && (
                <SignContainer>
                    <div className='container'>
                        <div className="col-1 hidden-m"/>
                        <div className="col-10">
                            <Container>
                                <Block primaryColor={settings.signUpBackgroundColor}>
                                    <CloseButton onClick={hidePopup}>
                                        <img src={settings.signUpBackgroundColor ? CrossIconWhite : CrossIcon} alt="Close"/>
                                    </CloseButton>
                                    {settings.signUpHeading ?
                                        <h2>{settings.signUpHeading}</h2>
                                        : (
                                            <h2>
                                                Get the largest selection and best deals for
                                                your next break in your inbox
                                                by becoming a member of the Snaptrip family.
                                            </h2>
                                        )}
                                    <Form onSubmit={submit} primaryColor={settings.signUpBackgroundColor}>
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            aria-label="Your email address"
                                            required
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <p>
                                            By entering your email address, you agree to
                                            {" "}
                                            <a href="https://www.snaptrip.com/term-and-conditions" target="_blank">
                                                Snaptrip's Terms of service
                                            </a>,
                                            {" "}
                                            <a href="https://www.snaptrip.com/privacy-policy">Privacy policy</a>
                                            {" "}
                                            and to receive email updates, news, & offers from
                                            {" "}
                                            <a href="https://www.snaptrip.com/" target="_blank">Snaptrip</a>
                                            .
                                        </p>
                                        <button type="submit">Sign up</button>
                                    </Form>
                                </Block>
                            </Container>
                        </div>
                    </div>
                    <Overlay/>
                </SignContainer>
            )}
        </>
    );
};

export default SignUp;