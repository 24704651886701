import React from "react";
import styled from "styled-components";
import Container from "../Core/Container";
import Icon from "../Core/Icon";

const Background = styled.div`
  background: #4a4a4a;
  width: 100vw;
  height: 2.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 2.75rem;
  
  span {
    display: inline-block;
    font-size: 0.8rem;
    color: var(--white);
  }
  
  svg {
    height: 2.75rem;
    width: 2.5rem;
    display: inline-block;
    position: relative;
    fill: var(--white);
    margin: auto 0.5rem -0.25rem;
  }

  a {
    display: flex;
  }
`;

const Bar = () => {
    return (
      <Background>
        <Container>
          <Banner>
            <span>
              Part of the
            </span>
            <a href="https://www.snaptrip.com/" target="_blank" rel="noreferrer">
              <Icon name="icon-snaptrip" />
            </a>
            <span>family</span>
          </Banner>
        </Container>
      </Background>
    );
};

export default Bar;