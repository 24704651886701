import React from "react";
import Layout from "./src/components/Layout/Layout";

export function wrapPageElement({element, props }) {
  let url;

  if ( props.data.region ) {
    url = props.data.region.slug.current;
  } else if ( props.data.property ) {
    url = props.data.property.slug.current;
  } else if ( props.data.blog ) {
    url = props.data.blog.slug.current;
  } else if ( props.data.staticPage ) {
    url = props.data.staticPage.slug.current;
  } else if ( props.data.homepage ) {
    url = "/"
  } else if ( props.data.ppc ) {
    url = "/ppc/"
  } else if ( props.data.blogs ) {
    url = "/blog/"
  } else if (props.location.pathname.includes("/contact/")) {
    url = "/contact/"
  } else {
    url = "/"
  }
  
    return(
      <Layout {...props} url={url}>
        {element}
      </Layout>
    );
}

export const onRenderBody = ({ setHeadComponents }) => {
  setHeadComponents([
    <script
      async
      key="google-optimize"
      src="https://www.googleoptimize.com/optimize.js?id=OPT-KXL2GFS"
    />,
  ])
}