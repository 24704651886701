import React from "react";
import styled from "styled-components";
import {Link} from "gatsby";
import breakpoints from "../../../styles/breakpoints";
import Links from "./Links";
import Icon from "../../Core/Icon";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-inline-start: 0;
  margin: 0;
  padding: 1.25rem 1.25rem 0 1.25rem;

  @media only screen and (min-width: ${breakpoints.lg}){
    flex-direction: row;
    align-items: center;
    padding: 0;
  }
`;

const Li = styled.li`
  margin: 0 0 1.25rem 0;
  width: 100%;

  @media only screen and (min-width: ${breakpoints.lg}){
    margin: 1.17rem 1.25rem 0 0;
    padding: 0 0 1.17rem 0;
    width: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;

    &:last-of-type {
      margin: 1.17rem 0 0 0;
    }
  }
`;

const LinkStyled = styled(Link)`
  font-size: .8rem;
  line-height: 1.188rem;
  color: var(--white);
  text-decoration: none;
  position: relative;
  font-weight: ${props => props.selected ? "700" : "normal"};
  
  @media only screen and (min-width: ${breakpoints.lg}) {
    font-weight: normal;
    width: calc(100% + 20px);
  }

  @media only screen and (min-width: ${breakpoints.xl}) {
    font-size: 1rem;
  }
`;

const NavElement = styled.span`
  font-size: .8rem;
  line-height: 1.188rem;
  color: var(--white);
  text-decoration: none;
  position: relative;
  font-weight: ${props => props.selected ? "700" : "normal"};
  cursor: default;

  @media only screen and (min-width: ${breakpoints.lg}) {
    font-weight: normal;
    width: calc(100% + 20px);
    transition: .2s;
  }

  @media only screen and (min-width: ${breakpoints.xl}) {
    font-size: 1rem;
  }
`;

const LinkBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LinksContainer = styled.div`
  display: ${props => props.opened ? "block" : "none"};
  list-style: none;
  transition: .2s;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    position: absolute;
    z-index: 999;
    background: rgba(0,0,0,0.9);
    top: 5.5rem;
    box-sizing: border-box;
    display: block;
    opacity: ${props => props.opened ? "1" : "0"};
    height: ${props => props.opened ? "calc(90vh - 132px)" : "0"};
    transition: .3s;
    min-width: 13.75rem;
    overflow: scroll;
    min-height: ${props => (props.opened && props.fullWidth) ? "min-content" : "min-content"};
    max-height: ${props => props.fullWidth && "100vh"};
    left: ${props => props.fullWidth && "0"};
    right: ${props => props.fullWidth || props.penultimate && "0"};
    width: ${props => props.fullWidth && "100%"};
  }

  @media only screen and (min-width: ${breakpoints.xxl}) {
    right: ${props => props.penultimate && "unset"};
  }
`;

const IconBox = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  transform: ${props => props.opened ? "rotate(180deg)" : "rotate(0deg)"};
  transition: .3s;
  cursor: pointer;
  display: block;
  margin: 0 2px 0 auto;
  
  svg {
    fill: ${props => props.opened ? "var(--secondary)" : "var(--white)"};
    width: 1.5rem;
    height: 1.5rem;
  }

  @media only screen and (min-width: ${breakpoints.lg}){
    display: none;
  }
`;

const NavLinks = ({links, isDesktop, headerVisible, linksOpen, toggleLink, closeMenu}) => {
    const penultimate = links.length - 2;

    return (
      <Ul>
        {links.map((link, i) => {
            const navLinks = link.subLinks;
            const opened = linksOpen === i;
            const fullWidth = (i === 0) || (i === 1);
            let pathname;

            if (typeof window !== "undefined") {
              pathname = window.location.pathname;
            }

            return (
              <Li key={i} onMouseEnter={() => toggleLink(i, true)} onMouseLeave={() => toggleLink(i, true)}>
                <LinkBlock>
                  {link.url ?
                      (
                        <LinkStyled
                          to={link.url}
                          target={link.target ? link.target : "_self"}
                          active={link.url === pathname ? 1 : 0}
                          aria-label={link.name}
                          selected={linksOpen === i}
                          onClick={() => closeMenu()}
                        >
                          {link.name}
                        </LinkStyled>
                      )
                        :
                      (
                        <NavElement aria-label={link.name} target={link.target ? link.target : "_self"} selected={linksOpen === i} onClick={() => closeMenu()}>
                          {link.name}
                        </NavElement>
                    )}
                  {navLinks && (
                  <IconBox onClick={() => toggleLink(i, false)} opened={opened}>
                    <Icon name="icon-arrow" />
                  </IconBox>
                )}
                </LinkBlock>

                {navLinks && (
                <LinksContainer opened={opened && headerVisible} fullWidth={fullWidth} penultimate={penultimate === i}>
                  <Links closeMenu={closeMenu} otherProps={link.name === "Other properties"} fullWidth={fullWidth} navLinks={navLinks} isDesktop={isDesktop} />
                </LinksContainer>
                 )}
              </Li>
            );
        })}
      </Ul>
    );
};

export default NavLinks;