import React from "react";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  position: relative;
  padding: ${props => props.fullWidthMobile ? "0;" : "0 1.25rem;"};

  @media only screen and (min-width: ${breakpoints.xl}) {
    max-width: 80rem; /*1280px*/
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    padding: 0 1.25rem;
  }
`;

const CoreContainer = ({ children, className, fullWidthMobile }) => {

    return (
      <StyledContainer fullWidthMobile={fullWidthMobile} className={className}>
        {children}
      </StyledContainer>
    );
};

export default CoreContainer;