const breakpoints = {
    xxl: "1400px",
    xl: "1200px",
    lg: "1024px",
    md: "768px",
    sm: "576px",
    xs: "575px",
    desktop: "1024px"
};

export default breakpoints;