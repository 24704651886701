import React, {useState} from "react";
import styled from "styled-components";
import {Link} from "gatsby";
import breakpoints from "../../../styles/breakpoints";
import SubLinks from "./SubLinks";
import Icon from "../../Core/Icon";

const Section = styled.div`
  max-width: 80rem;
  margin: 0 auto;
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (min-width: ${breakpoints.lg}) {
    padding: ${props => props.fullWidth ? "1rem 1.25rem" : "1rem"};
  }
`;

const Ul = styled.ul`
  list-style: none;
  padding-inline-start: 1rem;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${breakpoints.sm}) {
    padding-inline-start: 1.5rem;
  }
  
  @media only screen and (min-width: ${breakpoints.lg}) {
    padding-inline-start: 0;
    display: ${props => props.fullWidth && "grid"};
    grid-template-columns: ${props => props.fullWidth && !props.otherProps ? "repeat(5, 1fr)" : props.fullWidth && props.otherProps ? "1fr 1fr 3fr" : "" };
    row-gap: ${props => props.fullWidth && "1.25rem"};
  }
`;

const Li = styled.li`
  padding: 0.5rem 0;
  margin: 0 1rem 0 0 !important;
  
  @media only screen and (min-width: ${breakpoints.sm}) {
    margin: 0 1.5rem 0 0 !important;
  }
  
  @media only screen and (min-width: ${breakpoints.lg}) {
    padding: 0.5rem 0;
    
    &:last-of-type {
      padding-bottom: 0;
    }
  }
`;

const LinkStyled = styled(Link)`
  color: var(--white);
  text-decoration: none;
  font-weight: ${props => props.selected ? "700" : "normal"};
  white-space: nowrap;

  @media only screen and (min-width: ${breakpoints.lg}) {
    font-size: ${props => props.fullWidth ? "1.1rem" : "1rem"};
    font-weight: ${props => props.fullWidth && "700"};
    padding-bottom: ${props => props.fullWidth && "0.625rem"};
    border-bottom: ${props => props.fullWidth && "2px solid var(--secondary)"};
    white-space: ${props => props.fullWidth && "nowrap"};
    
    &:hover {
      font-weight: 700;
    }
  }
`;

const LinkBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const IconBox = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  transform: ${props => props.opened ? "rotate(180deg)" : "rotate(360deg)"};
  transition: .3s;
  cursor: pointer;
  display: block;
  margin: 0 2px 0 auto;
  
  svg {
    fill: ${props => props.opened ? "var(--secondary)" : "var(--white)"};
    width: 1.5rem;
    height: 1.5rem;
  }

  @media only screen and (min-width: ${breakpoints.lg}){
    display: none;
  }
`;

const NavElement = styled.div`
  color: var(--white);
  text-decoration: none;
  font-weight: ${props => props.selected ? "700" : "normal"};
  white-space: nowrap;

  @media only screen and (min-width: ${breakpoints.lg}) {
    font-size: ${props => props.fullWidth ? "1.1rem" : "1rem"};
    font-weight: ${props => props.fullWidth && "700"};
    padding-bottom: ${props => props.fullWidth && "0.625rem"};
    border-bottom: ${props => props.fullWidth && "2px solid var(--secondary)"};
    white-space: ${props => props.fullWidth && "nowrap"};
    
    &:hover {
      font-weight: 700;
    }
  }
`;

const Links = ({ navLinks, isDesktop, fullWidth, otherProps, closeMenu}) => {
    const [linksOpen ,setLinksOpen] = useState(null);

    const toggleLink = (i, executeOnDesktop) => {
        if (isDesktop && executeOnDesktop) {
            setLinksOpen((linksOpen !== i) ? i : null);
        }

        if (!isDesktop && !executeOnDesktop){
            setLinksOpen((linksOpen !== i) ? i : null);
        }
    };

    return (
      <Section fullWidth={fullWidth}>
        <Ul fullWidth={fullWidth} otherProps={otherProps}>
          {navLinks.map((link, i) => {
                    const subLinks = link.links;
                    const opened = linksOpen === i;

                    return (
                      <Li
                        key={i}
                        onMouseEnter={() => toggleLink(i, true)}
                        onMouseLeave={() => toggleLink(i, true)}
                        fullWidth={fullWidth}
                      >
                        <LinkBlock opened={linksOpen === i}>
                          {link.url ? (
                            <LinkStyled
                              to={link.url}
                              key={i}
                              aria-label={link.name}
                              onClick={() => closeMenu()}
                              selected={subLinks && opened}
                              fullWidth={fullWidth ? 1 : 0}
                            >
                              {link.name}
                            </LinkStyled>
                              )
                              : (
                                <NavElement
                                  key={i}
                                  selected={subLinks && opened}
                                  onClick={() => closeMenu()}
                                  fullWidth={fullWidth ? 1 : 0}
                                >
                                  {link.name}
                                </NavElement>
                              )}
                          {subLinks && (
                          <IconBox onClick={() => toggleLink(i, false)} opened={opened}>
                            <Icon name="icon-arrow" />
                          </IconBox>
                        )}
                        </LinkBlock>
                        {subLinks && (<SubLinks closeMenu={closeMenu} otherProps={otherProps && link.name === "Glamping"} opened={opened} subLinks={subLinks} isDesktop={isDesktop} fullWidth={fullWidth} />)}
                      </Li>
                    );
                })}
        </Ul>
      </Section>
    );
};

export default Links;