import React from "react";

const Icon = (props) => {
    const {name} = props;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className={`icon ${name}`}
      >
        <use xlinkHref={`#${name}`} />
      </svg>
    );
};

export default Icon;